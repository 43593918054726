import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {SNACKBAR_BUTTON_TYPES} from '../../assets/Data/LBSEnum';
import {BookingContext} from '../../pages/application/Application';
import BookingService from '../../services/booking';
import {CreateBooking} from '../../types/Booking';
import {Item} from '../../types/Item';
import Instance from '../../util/axios';
import {bookingPrice} from '../../util/calculator/calculations';
import getDeliveryOption from '../../util/getDeliveryOption';
import useErrorState from '../../util/reducers/errorContext';
import useGlobalState from '../../util/useGlobalState';
import Button from '../Button/Button';
import AddCardModel from '../modals/AddBankModel/AddCardModel';
import BorrowerAgreementModal from '../modals/AgreementModal/BorrowerAgreementModal';
import ApplicationItemCard from './ApplicationItemCard';
import BorrowApplicationCosts from './BorrowApplicationCosts';
import BorrowOverviewFooter from './BorrowOverviewFooter';
import ExtendApplicationCosts from './ExtendApplicationCosts';
import ExtendOverviewFooter from './ExtendOverviewFooter';
import './ItemOverview.css';
import PaymentMethodModal from '../modals/PaymentMethod/PaymentMethodModal';
import socket from '../../socket';
import getChannelId from '../../util/checkIsChannelExist';

export default function ItemOverview() {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [isPaymentMethodModalVisible, setPaymentMethodIsModalVisible] =
    //     useState(false);
    const {state, dispatch} = useContext(BookingContext);
    const {errorDispatch} = useErrorState();
    const [addCardOpen, setAddCardOpen] = useState(false);
    const globalState = useGlobalState();
    const user = globalState.state.user;

    const {
        item,
        startDate,
        endDate,
        isDeliverySelected,
        isPickupSelected,
        borrowerAddress,
        bookingCalculator,
        mode,
        appliedEndDate,
        bookingDuration,
        appliedStartDate,
        rescheduleBookingId,
        paymentMethod,
    } = state;

    const history = useHistory();

    // const extensionPrice = calculateExtensionPrice({
    //   bookingCalculator,
    //   bookingDuration,
    // })
    const extensionPrice = bookingPrice(
        appliedStartDate,
        endDate,
        item,
        bookingDuration,
    );

    // Set borrower address if address has not been set
    const setBorrowerAddressFallback = () =>
        dispatch({
            type: 'setBorrowerAddress',
            data: item.address,
        });

    useEffect(() => {
        if (!borrowerAddress) {
            setBorrowerAddressFallback();
        }
        return;
    }, []);

    const saveBooking = async () => {
        const bookingInfo = getBookingInfo();
        try {
            setIsLoading(true);
            await makeBooking(bookingInfo, item);
        } catch (error: any) {
            console.log('error', error?.response?.data?.message);
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        error?.response?.data?.message ??
                        'Failed to book this item. Please check details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getBookingInfo = () => {
        return {
            borrowerAddress: borrowerAddress,
            borrowerId: user.id,
            itemId: item.id,
            status: 'APPLIED',
            error: false,
            deliveryOption: getDeliveryOption(
                isDeliverySelected,
                isPickupSelected,
            ),
            startDate: startDate,
            endDate: endDate,
            isCOD: item.isCOD,
            totalPrice: item.price
                ? bookingCalculator?.calculateTotalPrice()
                : '0',
            itemPrice: item.price,
            discount: item.discount,
            deliveryPrice: isDeliverySelected ? item.deliveryPrice : 0,
            pickupPrice: isPickupSelected ? item.pickupPrice : 0,
            timeOffset: new Date().getTimezoneOffset(),
            rescheduleBookingId: rescheduleBookingId,
        };
    };

    const unblockUser = async () => {
        try {
        } catch (e) {
            // console.log(e)
        }
    };

    // const handleChangePaymentMethod = (data: any) => {
    //     if (!user?.stripe?.customerId && data === 'card') {
    //         setAddCardOpen(true);
    //     } else {
    //         dispatch({
    //             type: 'setPaymentMethod',
    //             data: data,
    //         });
    //     }
    // };
    const handleCheckCardAvailable = () => {
        console.log(user?.stripe?.customerId, 'user?.stripe?.customerId');
        if (!user?.stripe?.customerId && !item.isCOD) {
            setAddCardOpen(true);
        } else {
            setIsModalVisible(true);
        }
    };

    const sendEnquiry = async (item: Item, type: 'BORROW' | 'EXTENSION') => {
        let chatText =
            type === 'BORROW'
                ? `${user.firstName} ${user.lastName} has enquired about your ${item.title}`
                : `${user.firstName} ${user.lastName} has requested an extension about your ${item.title}`;

        let channelId = await getChannelId(user?.id!, item.userId);
        const message = {
            text: chatText,
            userId: user?.id,
            receiverId: item?.user?.id,
            metadata: {
                enquiry: true,
                itemName: item.title,
                ownerName: item.user?.firstName + ' ' + item.user?.lastName,
                bookingDate: new Date(),
            },
            user: {
                username: user?.firstName,
            },
            channelId,
        };
        socket.emit('chat', message);
    };

    const makeBooking = async (bookingInfo: CreateBooking, item: Item) => {
        const {data} = await Instance.post('/bookings', bookingInfo);
        await sendEnquiry(item, 'BORROW');
        if (!data) return;
        history.push({
            pathname: `/item/${item.id}`,
            state: {bookingCreated: true, price: bookingInfo.totalPrice},
        });
    };

    const requestExtension = async () => {
        if (
            !bookingDuration ||
            // !extensionPrice ||
            !endDate ||
            !appliedEndDate ||
            !bookingCalculator
        )
            return;
        try {
            setIsLoading(true);
            await BookingService.requestExtension(bookingDuration.bookingId, {
                endDate: endDate.toISOString(),
                startDate: appliedEndDate.toISOString(),
                itemPrice: item.price,
                discount: item.discount,
                // totalPrice: parseInt(bookingCalculator.calculateTotalPrice()),
                totalPrice: extensionPrice ? extensionPrice : 0,
            });
            await sendEnquiry(item, 'EXTENSION');
            history.push({
                pathname: `/user/trades`,
                state: {extensionCreated: true},
            });
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                // console.log(error.response)
            }
            if (error.message.includes('extension overlaps')) {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Booking extension overlaps with existing bookings.',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            } else {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Failed to apply an extension to this item. Please check details and try again.',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            }
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div className='ApplicationOverviewContainer'>
            <div className='ItemOverviewCardContainer'>
                <span className='ApplicationOverviewHeader'>
                    Application Overview
                </span>
                <ApplicationItemCard item={item} />
            </div>
            <div>
                <span className='ApplicationOverviewSubHeader'>
                    Itemised Costs
                </span>
                {mode === 'APPLY' && bookingCalculator ? (
                    <BorrowApplicationCosts
                        item={item}
                        isDeliverySelected={isDeliverySelected}
                        isPickupSelected={isPickupSelected}
                        bookingCalculator={bookingCalculator}
                        startDate={startDate}
                        endDate={endDate}
                    />
                ) : (
                    bookingCalculator && (
                        <ExtendApplicationCosts
                            itemPrice={item.price}
                            extensionPrice={extensionPrice}
                        />
                    )
                )}
                <div className='ItemOverviewItemContainer'>
                    <span className='ApplicationOverviewSubHeader'>Dates</span>
                    <span
                        onClick={() =>
                            dispatch({
                                type: 'setPage',
                                data: 'ItemAvailability',
                            })
                        }
                        className='ItemOverviewEditButton'
                    >
                        Edit Dates
                    </span>
                </div>
                {mode === 'APPLY'
                    ? startDate &&
                      endDate && (
                          <BorrowOverviewFooter
                              startDate={startDate}
                              endDate={endDate}
                          />
                      )
                    : endDate &&
                      appliedEndDate && (
                          <ExtendOverviewFooter
                              endDate={endDate}
                              originalEndDate={appliedEndDate}
                          />
                      )}
            </div>

            {/* <PaymentMethodModal
                item={item}
                paymentMethod={paymentMethod}
                handleChange={(e: any) =>
                    handleChangePaymentMethod(e.target.value)
                }
                isOpen={isPaymentMethodModalVisible}
                isCardAvailable={user?.stripe?.customerId ? true : false}
                setIsOpen={setPaymentMethodIsModalVisible}
                confirm={() => {
                    setIsModalVisible(true);
                }}
            /> */}
            <Button
                // onClick={() => setI/sModalVisible(true)}
                onClick={() => {
                    mode === 'EXTEND' || item.price === 0
                        ? setIsModalVisible(true)
                        : handleCheckCardAvailable();
                }}
                text='Next'
                style={{width: '75%', alignSelf: 'center', marginTop: '1rem'}}
            />
            <AddCardModel
                addCardOpen={addCardOpen}
                setAddCardOpen={setAddCardOpen}
                addcardFunction={() => {
                    setAddCardOpen(false);
                    history.push({
                        pathname: `/user/account`,
                    });
                }}
            />
            <BorrowerAgreementModal
                title={'Borrowers Agreement'}
                content={
                    "Be sure to read over your borrower's rights (Found on our website  <a href='/rental_agreement'>click here to check agreement</a>) and that you have the right licencing and permissions to operate this item. By tapping the Yes button you agree that you understand these terms."
                }
                isLoading={isLoading}
                open={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onClick={() =>
                    mode === 'APPLY' ? saveBooking() : requestExtension()
                }
            />
        </div>
    );
}
