import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import Instance from '../../util/axios';
import useErrorState from '../../util/reducers/errorContext';
import useGlobalState from '../../util/useGlobalState';
import useOnClickOutside from '../../util/useOnClickOutSide';
import UserButton from '../UserButton/UserButton';
import Logo from './../../assets/Logos/LBS_Logo_Flat_White.png';
import Login from './../loginButton/loginButton.js';
import Search from './../searchButton/searchButton.js';
import './header.css';
import Filter from './../../assets/filter.svg';

export default function Header() {
    const [HeaderSticky, setHeaderSticky] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchHistory, setSearchHistory] = useState([]);
    const [showRecentSearch, setShowRecentSearch] = useState(false);
    const history = useHistory();
    const {state} = useGlobalState();
    const {errorDispatch} = useErrorState();
    const {user} = state;
    const ref = useRef();

    const handleSubmit = async e => {
        e?.preventDefault();
        let string = '';
        if (history.location?.pathname === '/search/') {
            const queryString = history.location.search;
            const endIndex = queryString.indexOf('&');
            if (endIndex === -1) {
                if (searchText)
                    string = string.concat('?keyword=' + searchText);
            } else {
                // if (searchText)
                string = string.concat(
                    '?keyword=' +
                        searchText +
                        queryString.substring(endIndex, queryString.length),
                );
            }
        } else {
            if (searchText) string = string.concat('?keyword=' + searchText);
        }
        let textSearch = searchText.includes(',')
            ? searchText.replaceAll(',', '')
            : searchText;
        if (textSearch && !searchHistory.includes(textSearch)) {
            setSearchHistory(prev => {
                return [textSearch, ...prev];
            });

            let latLong = '';
            if (
                state?.currentLatLong?.latitude &&
                state?.currentLatLong?.longitude
            ) {
                latLong = `${state?.currentLatLong?.latitude},${state?.currentLatLong?.longitude}`;
            }
            // if (user?.id) {
            await Instance.post('/recent/recentSearch', {
                userId: user?.id ?? null,
                searchKeys: [textSearch, ...searchHistory],
                searchItem: searchText,
                latLong: latLong,
            });
            // }
        }
        history.replace(`/search/${string}`);
    };
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('keyword');

    useEffect(() => {
        if (paramValue) {
            setSearchText(paramValue);
        } else {
            setSearchText('');
        }
    }, [paramValue]);

    useEffect(() => {
        if (user) {
            fetchRecentSearch();
        }
    }, [user]);

    useOnClickOutside(ref, () => setShowRecentSearch(false));

    async function fetchRecentSearch() {
        let searches = await Instance.get(`/recent/${user?.id}`);
        if (searches?.data?.searchKeys) {
            setSearchHistory(searches?.data?.searchKeys);
        }
    }

    const handleTextMessage = message => {};

    useLayoutEffect(() => {
        const stickyHeaderEvent = () => {
            if (window.pageYOffset >= 120) {
                setHeaderSticky(true);
            } else {
                setHeaderSticky(false);
            }
        };
        window.addEventListener('scroll', stickyHeaderEvent);

        return () => {
            window.removeEventListener('scroll', stickyHeaderEvent);
        };
    }, []);

    // useEffect(() => {
    //   const interval = setTimeout((e) => {
    //     if(history.location?.pathname === "/search/" && searchText.length > 2){
    //       handleSubmit(e)
    //     }
    //   }, 1500)
    //   return () => clearTimeout(interval)
    // }, [searchText])

    const lendingRoute = path => {
        window.location.href = `${process.env.REACT_APP_LENDING_PAGE}${path}`;
    };
    return (
        <div className={`HeaderBar ${HeaderSticky ? 'HeaderBarSticky' : ''}`}>
            <Link to='' onClick={() => lendingRoute('/')}>
                <img src={Logo} alt='Logo' className='HeaderLogo' />
            </Link>

            <div className='SearchWrapper'>
                <form onSubmit={handleSubmit} className='SearchForm'>
                    <div className='HeaderSearchDiv'>
                        <input
                            type='text'
                            placeholder='Search for stuff'
                            className='TextInput HeaderSearchInput'
                            style={{padding: '0.5em 1em'}}
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        ></input>
                        <div className='h-3'>
                            {!isMobile && <Search height='16px' />}
                            {history.location?.pathname === '/search/' &&
                                searchHistory?.length > 0 && (
                                    <div
                                        onClick={() =>
                                            setShowRecentSearch(true)
                                        }
                                        className='recentSearch-text'
                                    >
                                        Recent searches
                                    </div>
                                )}
                        </div>
                        {searchHistory?.length > 0 && showRecentSearch && (
                            <div
                                className='cat-dropdown'
                                onBlur={() => console.log('calling')}
                                ref={ref}
                            >
                                <ul>
                                    {searchHistory?.map((dt, index) => (
                                        <>
                                            <li
                                                onClick={e => {
                                                    setSearchText(dt);
                                                    setShowRecentSearch(false);
                                                }}
                                                key={index}
                                                onBlur={() => {
                                                    // console.log('main', 'blur')
                                                }}
                                            >
                                                {dt}{' '}
                                                <span>
                                                    <KeyboardArrowRightIcon />
                                                </span>
                                            </li>
                                        </>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className='LoginWrapper'>
                {!user ? (
                    <Link to='/login' style={{width: '100%'}}>
                        <Login />
                    </Link>
                ) : (
                    <UserButton />
                )}
                <div className='search-in-mobile' id='search-in-mobile'>
                    <img src={Filter} alt='Logo' className='HeaderLogo' />
                </div>
            </div>
        </div>
    );
}
