import {useContext, useEffect, useState} from 'react';
import {ReactComponent as Logo} from '../../../assets/Logos/LogoRed.svg';
import LBSSwitch from '../../../components/LBSSwitch/LBSSwitch';
import {Fade} from '@material-ui/core';
import Button from '../../../components/Button/Button';
import ValidationTextInput from '../../../components/FormComponents/ValidationTextInput';
import ClearIcon from '@material-ui/icons/Clear';
import PlusIcon from '@material-ui/icons/Add';
import {POST_ITEM_PAGE} from '../../../assets/Data/LBSEnum';
import {validate} from 'validate.js';
import {discountConstraints} from '../../../util/validationConstraints';
import ValidationPopup from '../../../components/ValidationPopup/ValidationPopup';

export default function AdvancedDetails({context}) {
    const [errorMessages, setErrorMessages] = useState({});
    const {state, dispatch} = useContext(context);

    const {
        postItemDescription,
        postItemPrice,
        marketValue,
        postItemDiscount,
        isItemFree,
        isCODAvailable,
        // postItemDeliveryPrice,
        // postItemPickupPrice,
        // postItemDeliveryOption,
        itemSpecification,
        isPostItemDiscount,
    } = state;

    const [specificationEntries, setSpecificationEntries] = useState(
        Object.entries(itemSpecification).map(([key, value]) => ({
            id: Math.random().toString(36).substring(2), // Unique ID
            key,
            value,
        })),
    );

    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
    }, [
        postItemDescription,
        postItemPrice,
        postItemDiscount,
        // postItemDeliveryPrice,
        // postItemPickupPrice,
        // postItemDeliveryOption,
    ]);

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };

    const validateInputs = () => {
        if (isPostItemDiscount) {
            const validationErrors = validate(
                {postItemDiscount},
                discountConstraints,
            );
            if (validationErrors) {
                setErrorMessages(validationErrors);
                return false;
            }
        }
        if (!marketValue || postItemPrice >= marketValue) {
            setErrorMessages({
                marketValue: [
                    'Market value is required and cannot be less than the price.',
                ],
            });
            return false;
        }
        if (Object.keys(itemSpecification)?.length > 0) {
            const allValid = Object.entries(itemSpecification).every(
                ([key, value]) =>
                    key.trim() !== '' && // Ensure the key is not empty or whitespace
                    value !== null &&
                    value !== undefined &&
                    value.trim() !== '', // Ensure the value is not empty or whitespace
            );
            if (!allValid) {
                setErrorMessages({
                    specification: [
                        'Please fill in all keys and values, or delete the blank field.',
                    ],
                });
                return false;
            }
        }
        setErrorMessages({});
        return true;
    };

    // const disabledDeliveryOption = () => {
    //     if (!postItemDeliveryOption) return true;
    //     if (postItemDeliveryOption === 'BOTH') {
    //         if (!postItemDeliveryPrice || !postItemPickupPrice) return true;
    //     }
    //     if (postItemDeliveryOption === 'DELIVERY') {
    //         if (!postItemDeliveryPrice) return true;
    //     }
    //     if (postItemDeliveryOption === 'PICKUP') {
    //         if (!postItemPickupPrice) return true;
    //     }
    //     return false;
    // };

    const handleInputChange = (id, field, value) => {
        if (getErrorMessage('specification')) {
            setErrorMessages(prevErrors => {
                const {specification, ...rest} = prevErrors; // Destructure to exclude `specification`
                return rest; // Return the updated state without `specification`
            });
        }
        const updatedEntries = specificationEntries.map(entry => {
            if (entry.id === id) {
                if (field === 'key') {
                    return {
                        ...entry,
                        key:
                            value.charAt(0).toUpperCase() +
                            value.slice(1).toLowerCase(),
                    };
                } else if (field === 'value') {
                    return {
                        ...entry,
                        value,
                    };
                }
            }
            return entry;
        });

        setSpecificationEntries(updatedEntries);

        // Optionally, convert it back to an object to dispatch
        const updatedObject = updatedEntries.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setItemSpecification',
            data: updatedObject,
        });
    };

    const addKeyValuePair = () => {
        let newfield = {
            key: `key${Object.keys(itemSpecification).length + 1}`,
            value: '',
            id: Math.random().toString(36).substring(2),
        };
        let copy = [...specificationEntries, newfield];
        setSpecificationEntries(copy);
        const updatedObject = copy.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setItemSpecification',
            data: updatedObject,
        });
    };

    const removeKeyValuePair = id => {
        if (getErrorMessage('specification')) {
            setErrorMessages(prevErrors => {
                const {specification, ...rest} = prevErrors; // Destructure to exclude `specification`
                return rest; // Return the updated state without `specification`
            });
        }
        const filteredEntries = specificationEntries.filter(
            entry => entry.id !== id,
        );
        setSpecificationEntries(filteredEntries);

        const updatedObject = filteredEntries.reduce(
            (acc, {key, value}) => ({...acc, [key]: value}),
            {},
        );
        dispatch({
            type: 'setItemSpecification',
            data: updatedObject,
        });
    };

    return (
        <div className='RegistrationWrapper'>
            <div className='LoginMain'>
                <Logo
                    height='50px'
                    width='50px'
                    style={{marginBottom: '.5em'}}
                />

                <div className='LoginHeader'>Advanced Details</div>
                <div className='LoginText' style={{padding: 0}}>
                    <p>
                        These details are needed to tell Little Big Shed
                        borrowers finer details on items.
                    </p>
                    <p>
                        Please include as much detail as you can when filling
                        this out as borrowers will be more likely to borrow from
                        you if there is a great amount of detail.
                    </p>
                </div>
            </div>
            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Item Description</div>
                <div className='LoginText'>
                    Be as in-depth and detail orientated as you can be, users
                    like reading great description on products.
                </div>

                <div className='LoginHeader'>Description</div>
                <textarea
                    rows='10'
                    maxLength='254'
                    value={postItemDescription}
                    placeholder='Describe your item!'
                    className='LoginInput PostItem__TextArea'
                    onChange={e =>
                        dispatch({
                            type: 'setPostItemDescription',
                            data: e.target.value,
                        })
                    }
                />
            </div>

            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Payment Mode</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader'>Pay Online</div>
                    <div className='LenderSwitchInfoFlex LoginHeader'>
                        <LBSSwitch
                            onClick={() => {
                                dispatch({
                                    type: 'setIsCODAvailable',
                                    data: false,
                                });
                            }}
                            disabled={isItemFree}
                            isChecked={!isCODAvailable && !isItemFree}
                            text='Yes'
                        />
                    </div>
                </div>
                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader'>Pay Cash</div>
                    <div className='LenderSwitchInfoFlex LoginHeader'>
                        <LBSSwitch
                            onClick={() => {
                                dispatch({
                                    type: 'setIsCODAvailable',
                                    data: true,
                                });
                            }}
                            disabled={isItemFree}
                            isChecked={isCODAvailable}
                            text='Yes'
                        />
                    </div>
                </div>
            </div>
            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Extra Item Details</div>
                <div className='LoginText'>
                    Use the form below to dynamically add or edit extra details
                    for an item. Click 'Add Field' to add more details, or click
                    the cross icon to delete a field.
                </div>
                <div>
                    {specificationEntries.map(({id, key, value}) => (
                        <div
                            key={id}
                            className='item-specification-Flex LoginHeader'
                        >
                            <ValidationTextInput
                                maxLength={30}
                                inputType='text'
                                placeholder='Enter Title'
                                value={key}
                                onChange={e =>
                                    handleInputChange(id, 'key', e.target.value)
                                }
                            />
                            <ValidationTextInput
                                inputType='text'
                                placeholder='Enter Value'
                                maxLength={30}
                                value={value}
                                onChange={e =>
                                    handleInputChange(
                                        id,
                                        'value',
                                        e.target.value,
                                    )
                                }
                                style={{marginRight: '10px'}}
                            />
                            <ClearIcon
                                style={{color: '#ac172c'}}
                                onClick={() => removeKeyValuePair(id)}
                            />
                        </div>
                    ))}
                    <div className='add-key-value-btn'>
                        <button
                            onClick={addKeyValuePair}
                            className='ButtonAddKeyValue'
                        >
                            Add Field
                            <PlusIcon />
                        </button>
                    </div>
                    {getErrorMessage('specification') && (
                        <ValidationPopup
                            errorText={getErrorMessage('specification')}
                            label={'specification'}
                        />
                    )}
                </div>
            </div>
            <div className='LoginMain LoginMainNoMarg'>
                <div className='LoginHeader'>Charge out cost</div>

                <div className='BecomeLenderFlex'>
                    <div className='LoginHeader' style={{width: 'auto'}}>
                        Post item as free
                    </div>
                    <div className='LenderSwitchInfoFlex'>
                        <LBSSwitch
                            onClick={() => {
                                if (!isItemFree) {
                                    dispatch({
                                        type: 'setPostItemPrice',
                                        data: 0,
                                    });
                                    dispatch({
                                        type: 'setIsCODAvailable',
                                        data: false,
                                    });
                                    dispatch({
                                        type: 'setIsPostItemDiscount',
                                        data: false,
                                    });

                                    dispatch({
                                        type: 'setPostItemDiscount',
                                        data: 0,
                                    });
                                }
                                dispatch({
                                    type: 'setIsItemFree',
                                    data: !isItemFree,
                                });
                            }}
                            isChecked={isItemFree}
                            text='Yes'
                        />
                    </div>
                </div>
                <ValidationTextInput
                    label='Market Value ($)'
                    onChange={e =>
                        dispatch({
                            type: 'setMarketValue',
                            data: parseInt(e.target.value) || '',
                        })
                    }
                    inputType='number'
                    placeholder='Enter market value'
                    value={marketValue}
                    errorMessage={getErrorMessage('marketValue')}
                />

                {!isItemFree && (
                    <>
                        <div className='LoginText'>
                            Define a price you would like your item to be
                            charged out at on a per slot basis. Slots are 4
                            hours long.
                        </div>

                        <ValidationTextInput
                            label='Price ($)'
                            onChange={e =>
                                dispatch({
                                    type: 'setPostItemPrice',
                                    data: parseInt(e.target.value) || '',
                                })
                            }
                            inputType='number'
                            placeholder='Enter price'
                            value={postItemPrice}
                        />

                        <div className='LoginText'>
                            <b>Note :</b> As a Lender, the only cost of using
                            Little Big Shed is a 2.7% processing fee + 30c
                            transaction fee for domestic cards and 3.7%
                            processing fee + 30c transaction fee for
                            international cards, charged by Stripe, our
                            third-party payment provider. This amount is
                            deducted from what you get paid each time your item
                            is rented out.
                        </div>

                        <div className='BecomeLenderFlex'>
                            <div
                                className='LoginHeader'
                                style={{width: 'auto'}}
                            >
                                Off Peak Discount
                            </div>
                            <div className='LenderSwitchInfoFlex'>
                                <LBSSwitch
                                    onClick={() => {
                                        dispatch({
                                            type: 'setIsPostItemDiscount',
                                            data: !isPostItemDiscount,
                                        });
                                    }}
                                    isChecked={isPostItemDiscount}
                                    text='Yes'
                                />
                            </div>
                        </div>

                        <Fade
                            in={isPostItemDiscount}
                            timeout={300}
                            mountOnEnter
                            unmountOnExit
                        >
                            <div>
                                <div className='LoginText'>
                                    Define a price you would like your item to
                                    be charged out at, on a per slot basis.
                                    Slots are 4 hours long{` (% of Price)`}.
                                </div>
                                <ValidationTextInput
                                    inputType='number'
                                    value={postItemDiscount.toString()}
                                    onChange={e =>
                                        dispatch({
                                            type: 'setPostItemDiscount',
                                            data:
                                                parseInt(e.target.value) || '',
                                        })
                                    }
                                    placeholder='5%'
                                    errorMessage={getErrorMessage(
                                        'postItemDiscount',
                                    )}
                                />
                            </div>
                        </Fade>
                    </>
                )}
                <div className='post-item-next'>
                    <Button
                        text='Next'
                        onClick={() => {
                            const valid = validateInputs();
                            console.log(valid, 'valid>>>>>>>>>>>>');
                            if (!valid) return;
                            dispatch({
                                type: 'setCurrentPage',
                                data: POST_ITEM_PAGE.LOCATION,
                            });
                        }}
                        isDisabled={
                            !postItemDescription ||
                            (isItemFree ? false : !postItemPrice)
                        }
                    />
                </div>
            </div>
        </div>
    );
}
